// COMPONENTS
import React from 'react'
import FullHeightPage from 'templates/FullHeightPage'
import EridianClock from 'components/EridianClock'
// CONTENT
import content from 'content/eridian-clock.yml'

// Eridian clock page
const EridianClockPage = () => {
  const { card } = content
  return (
    <FullHeightPage content={content}>
      {card && <EridianClock content={card} />}
    </FullHeightPage>
  )
}

export default EridianClockPage
