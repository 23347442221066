// COMPONENTS
import React from 'react'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'

// Full height page (no header or footer) template
const FullHeightPage = ({ content, children }) => {
  const { seo } = content
  return (
    <Layout noFooter={true}>
      <SEO title={seo.title} image={seo.imageUrl} />
      { children }
    </Layout>
  )
}

export default FullHeightPage
