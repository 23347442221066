// Fun little clock to display Eridian time 
// described in Chapter 10 from Project Hail Mary
// by Andy Wier
// 
// Author: Neo Evolution
// Date: 5/20/2021
// 

const charMap = [ '\u2113', '\u0049', '\u0056', '\u03BB', '\u002B', '\u2200' ]
const eridTick = 2366     // 1 Eridian second = 2.366  Earth seconds
const eridCycle = 18396   //Math.round(7775*(eridTick/1000))      // Eridian clock cycle in seconds
const eridBase = 6        // Erian numbers are base-6
const eartBase = 10       // Earth numbers are base-10

// Convert a base-6 number string to Eridian symbol string
const toEridian = (base6Str) => {
  let eridStr = []
  for(let i = 0; i < base6Str.length; i++){
    eridStr.push(charMap[parseInt((base6Str.charAt(i)),10)])
  }
  return eridStr.toString().replace(/,/g,'') 
}

// Convert a date to Eridian
const dateToEridian = (dateStr) => {
  return toEridian((new Date(dateStr).getTime()/1000).toString(eridBase))
}

// Converts base-6 Eridian seconds
const timeToEridian = ( eridianSecs ) => {
  const epoch = (Math.round(Date.now()/1000))
  const epochBase6 = epoch.toString(eridBase)
  return { epoch, epochBase6, epochToErid: toEridian(epochBase6), eridTime: toEridian(eridianSecs) }
}

// Clock based on Eridian tick
const clk = () => {
  // Modulus of Earth seconds since book release (aka "book epoch", May the 4th be with you)
  let startTime = Math.round(((Date.now() - new Date('May 4 2021').getTime())/1000) % eridCycle )
  let currEridTime = (Math.round(startTime/(eridTick/1000))).toString(eridBase).padStart(5, '0')  //'00000'   // 5-digit, base-6 Eridian seconds, padded with zeros
  console.log({ startTime, eridCycle, currEridTime })
  const secsAdv = () => { 
    console.log(`Current Eridian time (base-6): ${currEridTime}`)
    console.log(convertToEridian(currEridTime))
    currEridTime = (parseInt(1+currEridTime,eridBase)+1).toString(eridBase).slice(1)        // Increment time by 1 Eridian second
  }
  setInterval(secsAdv, eridTick)
}

export { dateToEridian, timeToEridian, eridTick, eridCycle, eridBase, eartBase }

// ( async () => {
//   // Display Eridian base-6 characters 
//   charMap.map((char, index)=>{
//     console.log(`${index} = ${char}`)
//   })
//   let date = 'May 4 2021'
//   console.log(`${date} =  ${dateToEridian(date)}`)
//   clk()
// })()
