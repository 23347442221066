// COMPONENTS
import React, { useState, useEffect } from 'react'
import Section from 'layout/Section'
// BOOTSTRAP
import Spinner from 'layout/Spinner'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
// LOGIC
import { dateToEridian, timeToEridian, eridTick, eridCycle, eridBase, eartBase } from 'apis/EridianClock'

// Eridian clock from "Project Hail Mary" by Andy Weir
const EridianClock = ({ content }) => {
  const { section, firstName } = content
  const [eridTime, setEridTime] = useState(null)
  const [dateConvert, setDateConvert] = useState({ userDate: 'May 4 2021' })

  // Clock based on Eridian tick
  // Modulus of Earth seconds since book release (aka "book epoch", May the 4th be with you)
  let startTime = Math.round(((Date.now() - new Date('May 4 2021').getTime())/1000) % eridCycle )
  let currEridTime = (Math.round(startTime/(eridTick/1000))).toString(eridBase).padStart(5, '0')  //'00000'   // 5-digit, base-6 Eridian seconds, padded with zeros
  // console.log({ startTime, eridCycle, currEridTime })
  const secsAdv = () => { 
    // console.log(`Current Eridian time (base-6): ${currEridTime}`)
    // console.log(timeToEridian(currEridTime))
    setEridTime(timeToEridian(currEridTime))    
    currEridTime = (parseInt(1+currEridTime,eridBase)+1).toString(eridBase).slice(1)        // Increment time by 1 Eridian second
  }

  // Start interval timer once upon loading page
  useEffect(() => {
    setInterval(secsAdv, eridTick)
  },[])
  
  return (
  <Section content={section} bgVideo={section.bgVideo} isSPA={true}>
    <Container fluid>
      <Row className='align-items-center p-3'>
        { eridTime &&
        <>
        <Col id='time' className='my-4 center' xs={12} style={{fontFamily:'monospace'}}>
          {/* <h1>Project Hail Mary - Eridian Clock</h1> */}
          <h2>Eridian Time</h2>
          <h1 className='display-1'>{eridTime.eridTime}</h1>
        </Col>
        <Col id='earth-time' className='my-4 center' xs={12} style={{fontFamily:'monospace'}}>
          {/* <h4>Earth to Eridian Time</h4> */}
          <h2>{new Date(eridTime.epoch*1000).toUTCString()}</h2>
          <h1 className='display-1'>{eridTime.epochToErid}</h1> 
        </Col>
        </>
        }

        <Col id='user-time' className='my-4 center' xs={12} style={{fontFamily:'monospace'}}>
          {/* <h4>Convert date to Eridian Time</h4> */}
          <Form.Row>
            <Col className='text-center' xs={12}>
              <InputGroup size='lg'>
                <Form.Control
                  placeholder='Enter a date (eg May 4th 2021)'
                  value={dateConvert.userDate}
                  onChange={e => setDateConvert({ userDate: e.target.value }) }
                /> 
                <InputGroup.Append>
                  <Button onClick={ () => setDateConvert({ userDate: dateConvert.userDate, convertedDate: dateToEridian(new Date(dateConvert.userDate))})}>
                    Convert
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Form.Row>
          { dateConvert.convertedDate &&
          <>
          {/* <h4>Earth Datetime to Eridian</h4> */}
          <h1 className='display-1'>{ dateConvert.convertedDate }</h1> 
          </>
          }
        </Col>
      </Row>
    </Container>
  </Section>
  )
}

export default EridianClock
